import React, { useEffect, useRef } from "react";
import CustomLottie from './CustomLottie';

function VideoSection({playVideo, videoUrl, className, title, animation, scrollable,  scrollPersent }) {

  const video = useRef(null);
  const lottie = useRef(null);

  if (scrollable && video.current !== null) {
    let videoPlayStep = 20; // - швидкість відео
    // 0.2 - 20% від вікна прокручено 
    video.current.currentTime = (scrollPersent - 0.2) * videoPlayStep;
  }

  useEffect(()=>{
    if(video.current !== null && lottie.current !== null && playVideo) {
      lottie.current.play();
       if (!scrollable) {
        video.current.play();
       }
    }
  }, [playVideo, scrollable, animation])

  return (
    <section className = {className}>
      <div className="container">
        <div className="title-inner">
          <h1>{title}</h1>
          <CustomLottie 
            ref={lottie}
            autoplay={false}
            animationData={animation}
          />
        </div>
      </div>
        <video
            ref={video}
            width="100%"
            height="100%"
            preload="auto"
            muted
            // autoPlay
            playsInline
            // loop
         >
         <source src={videoUrl} type="video/mp4" />
        </video>
    </section>
  );
}

export default VideoSection;