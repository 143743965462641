import React from "react";
import css from "./TitleContact.module.scss";
// import { ReactComponent as LongArrow } from "../../../../../img/footer/longArrow.svg";
// import { NavLink } from "react-router-dom";

const TitleContact = ({t}) => {


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    // <NavLink to="/contacts">
      <div className={css["contactsWrapper_title"]}>
        {/* <h2 onClick={scrollToTop} className={css["contacts_title"]}>Контакти</h2> */}
        <h2 onClick={scrollToTop} className={css["contacts_title"]}>{t('contacts.subtitle')}</h2>
        {/* <LongArrow /> */}
      </div>
    // </NavLink>
  );
};

export default TitleContact;
