import React from "react";
import CustomLottie from './CustomLottie';
import sugar from "../json/sugar.json";
import sugar_en from "../json/sugar_en.json";
import sugar_dn from "../json/sugar_dn.json";

function HeadSection({translate, lang}) {

  return (
    <section className="head-page">
      <div className="container">
        <h1 className={lang}><span>{translate("header.title1")}</span> <span>{translate("header.title2")}</span > <span>{translate("header.title3")}</span></h1>
        <CustomLottie 
        animationData={(lang === 'ua' && sugar) || (lang === 'en' && sugar_en) || (lang === 'dn' && sugar_dn)} 
        autoplay={true}/>
      </div>
        <video
            width="100%"
            height="100%"
            preload="auto"
            muted
            autoPlay
            playsInline
            // loop
         >
         <source src='/videos/FIRST_SCREEN_ANIMATION.mp4' type="video/mp4" />
        </video>
    </section>
  );
}

export default HeadSection;