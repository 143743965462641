import React from "react";
import css from "./LogoInfo.module.scss";
// import { ReactComponent as Logo } from "./../../../../img/footer/logo.svg";

// import { useTranslation } from 'react-multi-lang'

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const LogoInfo = ({t}) => {
  // const t = useTranslation();
  return (
    <div className={css["logoInfoWrapper"]}>
      <div className={css["logo_content"]}>
        {/* <Logo className={css["logo"]}/> */}
        <p>
          © 1974 — {currentYear} <span>{t('footer.title')}</span>
        </p>
        {/* <p>{t('logoText')}</p> */}
      </div>
    </div>
  );
};

export default LogoInfo;
