import axios from "axios";

export const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL + '/api',
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
    },
});

export let errGlobal = false;

AxiosInstance.interceptors.response.use((response) => response, (error) => {
  errGlobal = true;
  throw error;
});