import HeadSection from "../components/HeadSection";
import Header from "../components/Header";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import AboutSection from "../components/AboutSection";
import VideoSection from "../components/VideoSection";
import { useEffect, useRef, useState } from "react";

import products from "../json/products.json";
import products_en from "../json/products_en.json";
import products_dn from "../json/products_dn.json";

import tryA from "../json/tryA.json";
import tryA_en from "../json/tryA_en.json";
import tryA_dn from "../json/tryA_dn.json";

import Footer from "../components/Footer";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
// import { SugarSlider } from '../components/SugarSlider';
import { useTranslation } from "react-multi-lang";
import { errGlobal } from "../axios/axios";

function Layout({ lang, setLang }) {
  const paralax = useRef(null);
  const [playOur, setPlayOur] = useState(false);
  const [playTry, setPlayTry] = useState(false);
  const [scrollPersent, setScollPercent] = useState(0);

  const [width] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(false);
  const outlet = useRef(null);
  const foot = useRef(null);  

  const param = useParams();
  const t = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  // language navigate
  useEffect(()=>{
    switch (location.pathname.substring(1, 3)) {
      case 'ua':
        setLang('ua');
        navigate(location.pathname.replace(`/${lang}/`, '/ua/'));
        break;
      case 'en':
        setLang('en');
        navigate(location.pathname.replace(`/${lang}/`, '/en/'));
        break;
      case 'dn':
        setLang('dn');
        navigate(location.pathname.replace(`/${lang}/`, '/dn/'));
        break;
      default:
        setLang('ua');
        // navigate('/ua');
        break;
    }
  }, [location.pathname, lang, navigate, setLang])

  useEffect(() => {
    // window.scrollTo(0, 0);
    if (width < 500) {
      setMobile(true);
    } else {
      scrollListener();
    }
  }, [width]);

  useEffect(() => {
    if (paralax.current !== null && param.id !== undefined) {
      paralax.current.scrollTo(3);
    }
    else {
      outlet.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }, [param.id]);

  function handleContactClick() {
    console.log('fdsadf')
    if (paralax.current !== null) {
      paralax.current.scrollTo(5);
    }
    else {
      foot.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  function scrollListener() {
    const containerInner = paralax.current?.container.current;

    containerInner.onscroll = () => {
      const { container, current } = paralax.current;
      const scrollpercent = parseFloat(
        (
          current /
          (container.current.scrollHeight - window.innerHeight)
        ).toFixed(1)
      );
      const currentScroll = parseFloat(
        (
          current /
          (container.current.scrollHeight - window.innerHeight)
        ).toFixed(3)
      );
      setScollPercent(currentScroll);

      if (scrollpercent === 0.3) {
        setPlayOur(true);
      }
      if (scrollpercent === 0.7) {
        setPlayTry(true);
      }
    };
    return () => {
      containerInner.onscroll = null;
    };
  }

  return !mobile ? (
    <div className="home">
      <Header 
        paralax={paralax} 
        location={location} 
        navigate={navigate} 
        lang={lang} 
        translate={t} 
        handleContactClick = {handleContactClick} />
      <Parallax pages={6} style={{ top: "0", left: "0" }} ref={paralax}>
        <ParallaxLayer offset={1} sticky={{ start: 0, end: 0.2 }}>
          <div style={{ height: "100px" }}></div>

          <HeadSection translate={t} lang={lang} />
        </ParallaxLayer>

        <ParallaxLayer offset={1} sticky={{ start: 1, end: 1 }}>
          <AboutSection translate={t} lang={lang} />
        </ParallaxLayer>

        <ParallaxLayer
          className="our-layer"
          offset={2}
          sticky={{ start: 2, end: 2.15 }}
        >
          <VideoSection
            playVideo={playOur}
            className={`our ${lang}`}
            scrollable={true}
            scrollPersent={scrollPersent}
            title={t("our.title")}
            videoUrl={"/videos/SECOND_SCREEN_ANIMATION.mp4"}
            animation={(lang === 'ua' && products) || (lang === 'en' && products_en) || (lang === 'dn' && products_dn)}
          />
        </ParallaxLayer>

        <ParallaxLayer
          className="sugar-layer"
          offset={3}
          sticky={{ start: 3, end: 3 }}
        >
          <Outlet />
        </ParallaxLayer>

        <ParallaxLayer
          className="try-layer"
          offset={4}
          sticky={{ start: 4, end: 4.1 }}
          style={{ paddingTop: "120px" }}
        >
          <VideoSection
            playVideo={playTry}
            scrollable={false}
            className={`try ${lang}`}
            scrollPersent={scrollPersent}
            title={t("try.title")}
            videoUrl={"/videos/THIRD_SCREEN_ANIMATION.mp4"}
            animation={(lang === 'ua' && tryA) || (lang === 'en' && tryA_en) || (lang === 'dn' && tryA_dn)}
          />
        </ParallaxLayer>

        <ParallaxLayer className="footer-layer" offset={5} sticky={{ start: 5, end: 5 }}>
          <Footer translate={t} lang = {lang} />
        </ParallaxLayer>
      </Parallax>
      {
        errGlobal && 
        <div className="errorHandler">
          <p>{t("errGlobal")}</p>
        </div>
      }

    </div>
  ) : (
    <div className="home-mobile">
      <Header
        location={location} 
        navigate={navigate} 
        lang={lang} 
        setLang={setLang} 
        translate={t} 
        handleContactClick = {handleContactClick} 
      />
      <HeadSection translate={t} lang={lang} />
      <AboutSection translate={t} lang={lang} />
      <VideoSection
        playVideo={true}
        className={`our ${lang}`}
        scrollable={false}
        // scrollPersent = {scrollPersent}
        title={t("our.title")}
        videoUrl={"/videos/SECOND_SCREEN_ANIMATION.mp4"}
        animation={(lang === 'ua' && products) || (lang === 'en' && products_en) || (lang === 'dn' && products_dn)}
      />
      <div className="outlet-inner" ref={outlet}>
        <Outlet />
      </div>
      <VideoSection
        playVideo={true}
        scrollable={false}
        className={`try ${lang}`}
        // scrollPersent = {scrollPersent}
        title={t("try.title")}
        videoUrl={"/videos/THIRD_SCREEN_ANIMATION.mp4"}
        animation={(lang === 'ua' && tryA) || (lang === 'en' && tryA_en) || (lang === 'dn' && tryA_dn)}
      />
      <div className="footer-inner" ref={foot}>
        <Footer translate={t} />
      </div>
      {
        errGlobal && 
        <div className="errorHandler">
          <p>{t("errGlobal")}</p>
        </div>
      }
    </div>
  );
}

export default Layout;
