import { AxiosInstance } from "./axios";

export const getProductItem= async (param)=>{
    // isLoading(true);
    const productItem = await AxiosInstance.get(`/products/${param}`);
    // isLoading(false);
    return productItem.data;
}

export const getProductAll = async (param)=>{
    // isLoading(true);
    const productItem = await AxiosInstance.get(`/products?pagination=false&language=${param}`, {               
        params: {
            "g[0]": "product:read",
            "g[5]": "product:language",
            "g[6]": "product:media",
            "g[7]": "product:attributeItems",
            "g[8]": "product:productInfos"
        }});
    // isLoading(false);
    return productItem.data;
}

export const getProductList = async ()=>{
    const productList = await AxiosInstance.get(`/categories?page=1&type=category`, {               
    params: {
        "g[1]": "cat:read",
        "g[2]": "cat:products",
        "g[3]": "product:read",
        "g[4]": "product:attributeItems"
    }});
    return productList.data;
}