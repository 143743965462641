import React from "react";
import css from "./Contacts.module.scss";
import TitleContact from "./TitleContact/TitleContact";
import ContactsList from "./ContactsList/ContactsList";

const Contacts = ({ contacts, t, lang }) => {

  return (
    <section className={css["contactsWrapper"]}>

    <h2 className={css.footerTitle}>{t('contacts.title').split(' ')[0]} <br/> {t('contacts.title').split(' ')[1]}</h2>
      <TitleContact t = {t} />
        <ContactsList t = {t} contacts={contacts} lang = {lang} />
    </section>
  );
};

export default Contacts;
