import React from "react";
import css from "./Footer.module.scss";
import allDataFooter from "./componentsFooter/dataFooter";
import Contacts from "./componentsFooter/Contacts/Contacts";
import LogoInfo from "./componentsFooter/LogoInfo/LogoInfo";
import Container from './../Container'

const { contacts } = allDataFooter;

const Footer = ({translate, lang}) => {
  return (
    <div className={css["footerWrapper"]}>
      <Container>
        <Contacts contacts={contacts} t = {translate} lang = {lang} />
        <LogoInfo t = {translate} />
      </Container>
    </div>
  );
};

export default Footer;
