import React, { useEffect, useRef } from "react";
import zavirAnimation from "../json/zavir.json";
import CustomLottie from "./CustomLottie";
import { useTranslation } from "react-multi-lang";

function SugarSlide({product, lang}) {

  const translate = useTranslation();
  const lottie = useRef(null);

  useEffect(()=>{
    if(lottie){
      // console.log(lottie.current);
    }
  },[])

  const currentProduct = {
     id : product.id,
     name: product.name,  
     media: [process.env.REACT_APP_SERVER_URL + (product.media[0] !== undefined ? product.media[0]?.contentUrl : product.translation.media[0]?.contentUrl)],
     packingIn: product?.attributeItems.find(elem=> elem.attribute.slug === 'packing_' + lang)?.name,
     weight: product?.attributeItems.find(elem=> elem.attribute.slug === 'weight_' + lang)?.name,
     carbohydrates: product?.productInfos.find(elem=> elem.keyName === 'carbohydrates_' + lang)?.value || product?.productInfos.find(elem=> elem.keyName === 'carbohydrates ')?.value, 
     producer: product?.productInfos.find(elem=> elem.keyName === 'producer_' + lang)?.value || product?.productInfos.find(elem=> elem.keyName === 'producer')?.value,
     nutritionalValue: product?.productInfos.find(elem=> elem.keyName === 'nutritional_' + lang)?.value.split(';') || product?.productInfos.find(elem=> elem.keyName === 'nutritional ')?.value.split(';'),
     minOrder: product?.productInfos.find(elem=> elem.keyName === 'volume_' + lang)?.value.split(';') || product?.productInfos.find(elem=> elem.keyName === 'volume')?.value.split(';'),
  };


  return (
      <div className={"product"}>
            <div className={"product__inner"}>
              <div className={"product__content"}>
                <h1>{currentProduct?.name}</h1>
                <CustomLottie 
                  ref={lottie}
                  autoplay={false}
                  animationData={zavirAnimation}
                />
                {currentProduct?.weight !== undefined &&
                <h4>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M15.9764 14.972L14.52 6.10636C14.383 5.27043 13.6725 4.6595 12.8409 4.6595H10.6527C10.9093 4.20397 11.056 3.67626 11.056 3.11431C11.056 1.39715 9.68526 0 7.99972 0C6.31454 0 4.94341 1.39715 4.94341 3.11431C4.94341 3.67626 5.09014 4.20397 5.34673 4.6595H3.15849C2.32656 4.6595 1.61642 5.27116 1.47945 6.1071L0.0233993 14.9739C-0.0593598 15.4765 0.0797768 15.9939 0.403224 16.3828C0.727033 16.7721 1.2019 17 1.7028 17H14.2981C14.799 17 15.2738 16.7721 15.5977 16.3828C15.9207 15.9932 16.0595 15.4751 15.9764 14.972ZM8.00008 1.81438C8.70335 1.81438 9.2758 2.39769 9.2758 3.11468C9.2758 3.83167 8.70335 4.41461 8.00008 4.41461C7.29644 4.41461 6.724 3.8313 6.724 3.11431C6.724 2.39732 7.29644 1.81438 8.00008 1.81438Z"
                        fill="white"
                      />
                    </svg>
                  </span> {currentProduct?.weight}
                </h4>}
                <div className={"product__content-inner"}>
                  <div className={"product__content-descr"}>
                    <p>
                      <span style={{ border: "2px solid #fff", padding: "7px 10px" }}>
                        1
                      </span>
                      {translate('products.category')}
                    </p>
                    <p>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_2323_28975)">
                            <path
                              d="M30 0H2C0.9 0 0 0.9 0 2V30C0 31.1 0.9 32 2 32H30C31.1 32 32 31.1 32 30V2C32 0.9 31.1 0 30 0ZM24.02 22.25H7.98L2.5 27.73V4.27L7.98 9.75H24.02L29.5 4.27V27.73L24.02 22.25ZM27.73 29.5H4.27L9.02 24.75H22.98L27.73 29.5ZM4.27 2.5H27.73L22.98 7.25H9.02L4.27 2.5Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2323_28975">
                              <rect width="32" height="32" fill="white" />
                            </clipPath>
                          </defs>

                          {/* </svg></span> Розфасований <br />в паперову упаковку</p> */}
                        </svg>
                      </span>
                      {currentProduct.packingIn ? currentProduct.packingIn : ""}
                    </p>
                  </div>
                  {currentProduct?.nutritionalValue !== undefined && (
                  <div className={"product__content-nutrit"}>
                    <p style={{ maxWidth: "150px" }}>
                      {translate('products.nutritionalValue')}
                    </p>
                      {currentProduct.nutritionalValue.map((item, index)=>{
                        return <p key={index} className={"measur"}>
                                <span>{item}</span>
                              </p>
                      })}
                  </div>)}
                  {currentProduct?.carbohydrates !== undefined && (
                  <div className={"product__content-carb"}>
                    <p style={{ maxWidth: "150px" }}>
                      {translate('products.carbohydrate')}
                    </p>
                    <p className={"measur"}>
                      <span>{currentProduct?.carbohydrates}</span>
                    </p>
                  </div>)}
                  {currentProduct?.minOrder !== undefined && (
                  <div className={"product__content-order"}>
                    <p style={{ maxWidth: "150px" }}>
                      {translate('products.order')}
                    </p>
                    {currentProduct?.minOrder !== undefined && (
                      currentProduct.minOrder.map((item,index)=>{
                        return <p key = {index} className={"measur"}>
                                <span>{item}</span>
                              </p>
                      })
                    )}
                  </div>)}
                  <p style={{ display: 'block', width: '100%' }}>
                    {currentProduct?.producer !== undefined && translate('products.producer') + currentProduct?.producer}
                  </p>
                </div>
              </div>
              <img src={currentProduct?.media} height={500} loading="lazy" alt="sugar" />
            </div>
          </div>
  );
}

export default SugarSlide;