import React from "react";

function AboutSection({translate, lang}) {

  return (
    <section className="about">
    <div className="container">
    <div className="about__content">
        <div className="about__content-block">
          <img src={`/img/keln_${lang}.svg`} alt="keln" className="keln" />
          <p>{translate("about.germany")}</p>
        </div>
        <div className="about__content-block">
          <img src={`/img/kelncherch_${lang}.svg`} alt="cherch" />
          <p>{translate("about.keln")}</p>
        </div>
        <div className="about__content-block">
          <img src={`/img/ukraine_${lang}.svg`} alt="ukr" />
          <p>{translate("about.ukraine")}</p>
        </div>
      </div>
      <div className="about__img">
        <img src="/img/diamant.png" alt="diamant" />
      </div>
    </div>
    </section>
  );
}

export default AboutSection;