
import React from "react";
import Lottie from "lottie-react";
import { forwardRef } from 'react';

const CustomLottie = forwardRef(function CustomLottie(props, ref) {
  return (
    <div className="animated-text">
        <Lottie
            lottieRef={ref} 
            animationData={props.animationData} 
            autoplay={props.autoplay}
            loop={false} 
        />
    </div>
  );
})

export default CustomLottie;