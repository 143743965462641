import React from "react";
import css from "./SocialList.module.scss";
import { ReactComponent as MessageIcon } from "../../../../../img/footer/message.svg";
import { ReactComponent as FaceBookIcon } from "../../../../../img/footer/faceBook.svg";
import { ReactComponent as TikTokIcon } from "../../../../../img/footer/tikTok.svg";
import { ReactComponent as InstagramIcon } from "../../../../../img/footer/instagram.svg";



const SocialList = ({translate}) => {

  return (
    <section className={css["contacts_social__wrapper"]}>
      <MessageIcon className={css["messageIcon"]}/>

      <p>{translate('contacts.producer.contact')}</p>

      <ul className={css["social__list"]}>
        <li>
          <a
            href="https://www.facebook.com/Diamant.tsukor"
            target="_blank"
            rel="noreferrer"
          >
            <FaceBookIcon className={css["icons"]}/>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/sugar_diamant/" target="_blank" rel="noreferrer">
            <InstagramIcon className={css["icons"]}/>
          </a>
        </li>
        <li>
          <a
            href="https://www.tiktok.com/@sugar.diamant"
            target="_blank"
            rel="noreferrer"
          >
            <TikTokIcon className={css["icons"]}/>
          </a>
        </li>
      </ul>
    </section>
  );
};

export default SocialList;
