import './css/global.css'
import './css/head.scss'
import './css/about.scss'
import './css/video.scss'
import './css/sugar.scss'
import React from 'react';
import { RouterProvider, createBrowserRouter} from "react-router-dom";
// import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
// import { Outlet } from "react-router-dom";
// import SlidePage from './pages/SlidePage'
import { setTranslations, setDefaultLanguage} from 'react-multi-lang'
import ua from './localisation/uk.json'
import en from './localisation/en.json'
import dn from './localisation/de.json'
import { useLang } from './hooks/useLang';
import Layout from './pages/Layout'
import { SugarSlider } from './components/SugarSlider'

// Do this two lines only when setting up the application
setTranslations({ua, en, dn})
const currentLang = localStorage.getItem('lang') || 'ua';
setDefaultLanguage(currentLang);

function App() {

  const {lang, setLang} = useLang();  

  const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout lang = {lang} setLang = {setLang}/>,
        errorElement: <NoPage />,
        children: [
        { index: true, element: <SugarSlider lang = {lang}/> },
        {
          path: "*",
          element: <SugarSlider lang = {lang}/>,
          errorElement: <NoPage/>,
        },
        {
          path: "/ua/clients/sugar/:id",
          element: <SugarSlider lang = {lang}/>,
          errorElement: <NoPage/>,
        },
        {
          path: "/en/clients/sugar/:id",
          element: <SugarSlider lang = {lang}/>,
          errorElement: <NoPage/>,
        },
        {
          path: "/dn/clients/sugar/:id",
          element: <SugarSlider lang = {lang}/>,
          errorElement: <NoPage/>,
        },
      ], 
    }
  ]);

  return (
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
  );
}

export default App;
