import { Link } from "react-router-dom";

const NoPage = () => {
    return (
      <div className="nopage">
        <div class="background">
          <div class="ground"></div>
        </div>
        <div class="container">
          <div class="left-section">
            <div class="inner-content">
              <h1 class="heading">404</h1>
              <p class="subheading"><Link to={'/'}>Повернутися на головну сторінку &#8594;</Link></p>
            </div>
          </div>
        </div>
        </div>
    );
};

export default NoPage;